import {combineReducers} from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import news from './news';
import bookmarks from './bookmarks';


export default combineReducers({
  error: errorReducer,
  auth:authReducer,
  news,bookmarks
})