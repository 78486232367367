import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
//import GitHubIcon from '@material-ui/icons/GitHub';
//import FacebookIcon from '@material-ui/icons/Facebook';
//import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
//import MainFeaturedPost from './MainFeaturedPost';
//import FeaturedPost from './FeaturedPost';
//import Main from './Main';
//import Sidebar from './Sidebar';
import Footer from './Footer';
//import post1 from './blog-post.1.md';
//import post2 from './blog-post.2.md';
//import post3 from './blog-post.3.md';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//import Login from './component/Login/Login';
//import { Link } from 'react-router-dom'
import './BetaVersion.css'
import Userfront from "@userfront/react";

import BVSignUp from './components/SignUpModule';
import BVSignIn from './components/SignInModule';
import BVPasswordReset from './components/PasswordReset';
import NewsList from './components/NewsList';
//import CategorySourceSearchForm from './components/CategorySourceSearchForm';
//import { setTopNews, clearTopNews } from './actions/news';
//import { connect } from 'react-redux';


Userfront.init("BetaVersionUser");

const SignupForm = Userfront.build({
  toolId: "nkmbbm",
});
const LoginForm = Userfront.build({
  toolId: "alnkkd",
});
const PasswordResetForm = Userfront.build({
  toolId: "dkbmmo",
});


/*const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  palette: {
    secondary: {
      main: '#000000'
    }
  }
}));
*/
const sections = [
  { title: 'BEST', url: 'https://best.beta-version.org'},
  { title: 'Academy', url: 'https://academy.beta-version.org'},
  //{ title: 'Find Partner', url: 'https://fp.beta-version.org'},
  //{ title: 'Test', url: './hub.html' },
  //{ title: 'FP', url: 'http://localhost:3001' },
  /*{ title: 'Politics', url: '#' },
  { title: 'Opinion', url: '#' },
  { title: 'Science', url: '#' },
  { title: 'Health', url: '#' },
  { title: 'Style', url: '#' },
  { title: 'Travel', url: '#' },*/
];


//const posts = [post1, post2, post3];

/*const sidebar = {
  title: 'About',
  description:
    'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  social: [
    { name: 'GitHub', icon: GitHubIcon },
    { name: 'Twitter', icon: TwitterIcon },
    { name: 'Facebook', icon: FacebookIcon },
  ],
};*/

export default function BetaVersion() {
  //const classes = useStyles();
  //const [token, setToken] = useState();

    return (
    <React.Fragment>
      <CssBaseline />

        <Header title="Beta Version" sections={sections} />
        <Container maxWidth="lg">
        <BrowserRouter>
         <Switch>
         <Route exact path="/">
             <NewsList/>
             {/*
                newsItemsTotal={news.newsItemsTotal}
                loading={news.newsLoading}
                newsItems={news.newsItems}
                theme={news.theme}
                loadMore={() => handleLoadMore()}
*/}
          {/*<MainFeaturedPost post={mainFeaturedPost} />
            <Grid container spacing={4}>
              {featuredPosts.map((post) => (
                <FeaturedPost key={post.title} post={post} />
              ))}
            </Grid>

          <Grid container spacing={5} className={classes.mainGrid}>
            <Main title="From the firehose" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
                     </Grid>
*/}
          </Route>

          <Route exact path="/login-1">
            <Login/>
          </Route>
          <Route exact path="/sign-up-1">
              <SignUp/>
          </Route>
          <Route exact path="/password-reset-1">
              <PasswordReset/>
          </Route>
          <Route exact path="/sign-in">
            <BVSignIn/>
          </Route>
          <Route exact path="/sign-up">
            <BVSignUp/>
          </Route>
          <Route exact path="/password-reset">
              {<BVPasswordReset/>}
          </Route>

          </Switch>
          </BrowserRouter>
      </Container>
      <Footer  />
    </React.Fragment>
  );
}
function SignUp() {
  return (
    <div>
      <SignupForm />
    </div>
  );
}
function Login() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}
function PasswordReset() {
  return (
    <div>
      <PasswordResetForm />
    </div>
  );
}

