//import logo from './logo.svg';
import React, {Component} from 'react'
import './App.css';
import BetaVersion from './BetaVersion';
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

import { loadUser } from './actions/authActions';
import { Provider } from 'react-redux';
import store from './store';
//import axios from 'axios';
//import { getNewsSource, setTheme } from './actions/news';
//import { getBookmarkItems } from './actions/bookmarks';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F44336'
    }
  }
});

class App extends Component{
  componentDidMount(){
    //StorageEvent.dispatch()
    console.log('App::componentDidMount going to load user.');
    store.dispatch(loadUser());
    //store.dispatch(getNewsSource());
    //store.dispatch(setTheme());
    //store.dispatch(getBookmarkItems());
  }
  render(){
  return (
    <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <BetaVersion/>
    </MuiThemeProvider>
    </Provider>
  );
  }
}

export default App;
