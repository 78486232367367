import React,{Component} from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {connect} from 'react-redux';

import {password_reset} from '../actions/authActions';

const useStyles  = theme => ({
    paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  snackbar:{
    position: 'relative',
    width:'100%',
    transform:'none',
    left:0,
    top:0
  },
  placeholder:{
    minHeight:'5rem',
  }
});


class BVPasswordReset extends Component{
  state = {
    email: '',
    open: false,
  };

  onChange = e =>{
    this.setState({[e.target.name]:e.target.value, open:false});
    //console.log('onChange: '+JSON.stringify(this.state))
  }

  onSubmit = e =>{
    e.preventDefault();
    const email = this.state.email;
    this.props.reset_password(email);
    
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({open:false});
  };
  render() {
    const { classes } = this.props;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit = {this.onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.onChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container justify="flex-end" className={classes.placeholder}>
            
            <Snackbar className={classes.snackbar} open={this.state.open}  anchorOrigin={ {vertical: 'top', horizontal: 'center'} }>
                <Alert onClose={this.handleClose} severity="info">
                    "If you have an account the instructions were sent to you."
                </Alert>
            </Snackbar>
          </Grid>
      </form>
      </div>
    </Container>
  );
}
};

export default connect(null, password_reset)(withStyles(useStyles)(BVPasswordReset));
