import React,{Component} from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
//import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import { useHistory } from "react-router-dom";

import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {signup} from '../actions/authActions';
import {clearErrors} from '../actions/errorActions';
//import { REGISTER_SUCCESS } from '../actions/types';
  //classes = makeStyles((theme) => ({
    const useStyles  = theme => ({
    //const useStyles = theme => ({
      paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      //paddingBottom:'1rem'
      //height:'100%'
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    snackbar:{
      position: 'relative',
      width:'100%',
      transform:'none',
      left:0,
      top:0
    },
    placeholder:{
      minHeight:'5rem',
    }
  });

class BVSignUp extends Component{
  state = {
    firstName:'',
    lastName:'',
    email: '',
    password: '',
    open: false,
    msg: null,
    severity:"success",
    //updateState:0,
    //submitted:0
  };
  static propTypes = {
    isAuthenticated:PropTypes.bool,
    error: PropTypes.object.isRequired,
    signup: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired

  };

  componentDidUpdate(prevProps){
    const {error, isAuthenticated} = this.props;
    console.log('componentDidUpdate: '+JSON.stringify(error) + 'auth = '+JSON.stringify(isAuthenticated));
    //var resetMessage = true;
    if (error !== prevProps.error){
      //this.setState({updateState:0});
      //console.log('componentDidUpdate: '+JSON.stringify(error));
      //resetMessage = false;
      if(error.id ==='REGISTER_FAIL'){
        this.setState({msg: error.msg.msg, open:true, severity:"error"});
      } /*else if(!error.id){
        this.setState({open:false, msg:"You account has been created", severity:"success"});

      } */else {
        this.setState({open: false});
      }

      //console.log('componentDidUpdate: state.msg = '+this.state.msg);
    }
    if (isAuthenticated && isAuthenticated !== prevProps.isAuthenticated){
      this.setState({open:true, msg:"You account has been created", severity:"success"});
     //let history = useHistory();
      //this.props.history.push('/');
      window.location.href="/";  
    }
    /*if (this.state.submitted === 1){
      this.setState({submitted:2, open:false});
    } else if (!error.id && this.state.submitted === 2){
      this.setState({open:true, msg:null, submitted: 0});
    }*/
    /*console.log('componentDidUpdate: end => error = '+JSON.stringify(prevProps));
    if (!error){
      console.log('componentDidUpdate: error '+ error);
    }
    */
/*
    if(this.state.submitted){
      if (resetMessage) this.setState({msg:null});
      this.setState({open: true, submitted:false});

    }*/
  }
  /*
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };*/

  onChange = e =>{
    this.setState({[e.target.name]:e.target.value, open:false});
    //console.log('onChange: '+JSON.stringify(this.state));
  }
  handleSubmitButtonClick = e =>{
    //this.setState({open:false});
    //this.setState({submitted:true});
  }
  onSubmit = e =>{
    //this.setState({open:false});
    //this.setState({submitted:1});
    console.log("before clearErrors");
    this.props.clearErrors();
    e.preventDefault();
    //console.log('onSubmit: '+JSON.stringify(this.state));
    const {firstName, lastName, email, password} = this.state;
    const newUser = {firstName, lastName, email, password};
    //console.log('onSubmit: newUser =  '+JSON.stringify(newUser));
    console.log("before signup");
    this.props.signup(newUser);
    console.log("after signup");
    

    //this.toogle();

  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open:false});
  };
  render() {
    const { classes } = this.props;
    return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}> {/*className={classes.paper}*/}
        <Avatar className={classes.avatar}> {/*className={classes.avatar}*/}
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit = {this.onSubmit}> {/*className={classes.form}*/} 
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                onChange={this.onChange}
                //inputRef={this.state.firstName}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.onChange}
              />
            </Grid>
            {/*<Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
    </Grid>*/}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.handleSubmitButtonClick}
            //onClick = {this.toggle}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/sign-in" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.placeholder}>
            
            <Snackbar className={classes.snackbar} open={this.state.open} /*autoHideDuration={6000}*/ onClose={this.handleClose} anchorOrigin={ { mt:3, vertical: 'top', horizontal: 'center' }}>

                  <Alert  severity={this.state.severity}>
                    {this.state.msg}
                  </Alert>
            </Snackbar>
      </Grid>
        </form>
      </div>
    </Container>
  );
}
};
const mapStateToProp = state =>({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
})

export default connect(mapStateToProp, {signup, clearErrors})(withStyles(useStyles)(BVSignUp));