import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {logout} from '../actions/authActions';
import Button from '@material-ui/core/Button';

class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired
  }
  render() {
    return (
    <Button variant="contained" size="small" align = "end" color="primary" className='mx-3' onClick={this.props.logout}>
      Logout
    </Button>
    );
  }
}

export default connect(null, {logout})(Logout);