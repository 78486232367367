import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import CrossStorageHub from './hub'
//module.exports = {
  //CrossStorageClient: require('./client.js'),
  //CrossStorageHub:    require('./hub.js')
//};

/*CrossStorageHub.init([
  {origin: /\.localhost:3001$/,            allow: ['get']},
  {origin: /:\/\/(www\.)?localhost:3001$/, allow: ['get', 'set', 'del']}
]);*/

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

