import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
//import { useControlled } from '@material-ui/core';
//import { Link } from 'react-router-dom'
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';
import Logout from './components/Logout';
//import {logout} from './actions/authActions';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));


function Header(/*{sections, title, auth}*/props) {
  /*const propTypes = {
    auth:PropTypes.object.isRequired,
  };*/
  const classes = useStyles();
  const { sections, title, auth } = props;
  const { isAuthenticated, user } = auth;
  console.log(JSON.stringify(props));
  //console.log("Header:"+ JSON.stringify(auth)+"sections:" + JSON.stringify(sections)+" : " + title);
  const authLinks = (
    <Fragment>
      <Box mx={3} px={3}><span><strong>{user ?`Wellcome ${user.firstName} ${user.lastName}` :'' }</strong></span></Box>
      <Logout/>{/*}
      <Button variant="contained" size="small" align = "end" color="primary" className='mx-3' onClick={logout}>
  Logout
  </Button>*/}
</Fragment>
  )
  const guestLinks = (
    <Fragment>
  <Button variant="contained" size="small" align = "end" color="primary" href="/sign-in" >
    Sign in
  </Button></Fragment>
  )

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        {/*<Button size="small">Subscribe</Button> */}

        <Link href = "/"
        underline="none"
        align="left"
        noWrap
        className={`${classes.toolbarTitle} no-visit`}
        color="secondary"
        //style={{ textDecoration: 'none' }}
        
        >
        <Box display="flex" alignItems="center">
        <Avatar alt="Beta Version Logo" src="bv-logo-rocket-round.png" variant="rounded" className={classes.rounded}/>

        <Typography
          component="h2"
          variant="h5"
          //color="inherit"
          align="left"
          noWrap
          className={classes.toolbarTitle}
          my="auto"
        >
          <Box ml="3rem" fontStyle="italic" fontWeight = {500}>{title}</Box>
          </Typography>
          </Box>
        </Link>

        {/*<IconButton>
          <SearchIcon />
        </IconButton>
        */}
        {isAuthenticated ? authLinks : guestLinks}


      </Toolbar>
      <Container maxWidth="lg">
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary} >

        {sections.map((section) => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            className={classes.toolbarLink}
            datParam={auth.token}
            //to={{pathname: section.url}}
            params={{ testvalue: "hello" }}
            target="_blank"
          >
            {section.title}
          </Link>
        ))}

      </Toolbar>
        </Container>
      </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};

const mapStateToProp = state =>({
  auth: state.auth,
});

export default connect(mapStateToProp, {})(Header);

